import {keyCodes} from 'ui-core/utility/js/utils';
import '../../../plugins/slide/js/slide';

(function (UX) {
    var slide = UX.slide;

    slide.setVariable = function () {
        slide.pillIconsInitLeft = null;
        slide.pillInformationImages = null;
        slide.pillInformationTruncateText = null;
        slide.$articleMainContent = $('.sticko__side-content');
        slide.$pillContent = $('.pill-content');
        slide.$pillSection = $('.pill-section');
        slide.mediaMoved = false;
    };

    slide.additionalControls = function () {
        if ($('.toc__section .contrib-metrics-bibliometrics ul.loa').length) {
            UX.slide.updateAuthorsInstit($('.toc__section .contrib-metrics-bibliometrics ul.loa'), true);
        }
        slide.setVariable();
        slide.controlContent();
        slide.controlSlide();
        slide.playVideoStream();
    };

    slide.controlContent = function () {
        // show all authors inside pill in case "bibliometrics" widget return empty
        var $authorsPillWrapper = $('.pill-authors-list'),
            $authorsBibliometrics = $authorsPillWrapper.find('.contrib-metrics-bibliometrics .loa'),
            $authorsPill;

        slide.pillInformationTruncateText = slide.$pillContent
            .find('.truncate-text, .truncate-list, .truncate-with-shadow')
            .removeClass('trunc-done');
        slide.pillInformationTruncateText.truncate('destroy');

        slide.pillInformationImages = new window.LazyLoad({elements_selector: '.pill-information__content .lazy'});

        setTimeout(function () {
            var searchTab, $accessPill;

            if (location.search !== '') {
                searchTab = location.search;
                $accessPill = $('.pill-access');
                if (searchTab.indexOf('accessTab') !== -1 && $accessPill.length) {
                    $accessPill.click();
                }
            }
        }, 200);

        if (!$authorsBibliometrics.length) {
            $authorsPill = $authorsPillWrapper.find('.pill-all-authors');
            if ($authorsPill.children().length) {
                $authorsPill.removeClass('hidden').removeAttr('aria-hidden');
            } else {
                if ($('.noAuthors').length === 0 && $('#ContribsAjax').length === 0) {
                    $authorsPillWrapper.append(
                        '<p class="info--text">There are no authors associated with this article</p>'
                    );
                }
            }
        } else {
            slide.updateAuthorsInstit($authorsBibliometrics, false);
        }
    };

    slide.controlSlide = function () {
        $(document).ready(function () {
            slide.pillIconsInitLeft = slide.$articleMainContent.offset().left + slide.$articleMainContent.outerWidth();
            slide.$pillContent.addClass('hidden');
            slide.$pillSection.css('left', slide.pillIconsInitLeft);
        });

        $(document).on('click', '#pill-media__contentcon', function () {
            if (!slide.mediaMoved) {
                slide.moveMediaToPill();
            }
        });

        $(window).on('orientationchange load resized', function () {
            setTimeout(function () {
                var $transplanted = $('.transplanted-clone .pill-list:visible');
                if ($transplanted) {
                    UX.tab.extraMargin = 50;
                    UX.tab.on.calculate($transplanted);
                    UX.tab.extraMargin = 25;
                }

                $('[class*=pill][class*=__content]:visible').css(
                    'height',
                    $(window).outerHeight() - calcMarginTopValue(true) /*eslint-disable-line*/
                );
                slide.on.slidePill();
            }, 200);
        });

        $(window).on('scroll orientationchange resized load', function () {
            slide.setTop();
        });

        $(window).on('click', function (e) {
            if (typeof e !== 'undefined') {
                if ($(e.target).hasClass('slide-is-active')) {
                    slide.on.hide(e);
                }
            }
        });
    };

    slide.updateAuthorsInstit = function ($authorsBibliometrics, toc) {
        $authorsBibliometrics.children('li').each(function () {
            var $this = $(this),
                doi = $this.find('.contrib-id').val(),
                $authorBiblioInst = $this.find('.auth-institution'),
                $authorInst = $('.citation [data-doi="' + doi + '"]');

            if (toc) {
                $authorBiblioInst = $this.find('.creative-work__meta');
                $authorInst = $('.item-meta__info [data-doi="' + doi + '"]');
            }

            if ($authorInst.length) {
                $authorBiblioInst.html($authorInst.html());
            }
        });
    };

    slide.moveMediaToPill = function () {
        var $container,
            extraLinks,
            originalVideoId,
            mediaItem,
            $cfContainer,
            $cfStream,
            cfExtraLinks,
            cfOriginalVideoId,
            cfPoster,
            cfMediaItem,
            cfName,
            cfText,
            $metaSelector;
        if ($('.flowPlayer').length) {
            $('.flowPlayer').each(function () {
                $container = $(this).parent();
                originalVideoId = '#' + $(this).attr('id');
                mediaItem = UX.flowPlayer.clone($container);
                extraLinks =
                    '<div class="extra-links"><a href="' +
                    originalVideoId +
                    '" title="Go to Video" class="back"><i class="icon-subdirectory_arrow_left"></i></a></div>';
                $('.pill-media__content').append('<li class="section__separator">' + mediaItem + extraLinks + '</li>');
            });
            if (UX.flowPlayer.ready) {
                UX.flowPlayer.initVideo();
            }
        }
        if ($('.cloudflare-stream-container').length) {
            $('.cloudflare-stream-container').each(function () {
                $cfContainer = $(this);
                $cfStream = $cfContainer.find('.cloudflare-stream-player');
                cfOriginalVideoId = $cfStream.attr('id');
                cfPoster = $cfStream.attr('poster');
                $metaSelector = $cfStream.closest('.article-media__item');
                cfName = $metaSelector.find('.file-name').text();
                cfText = $metaSelector.find('.info--text').text();

                cfMediaItem =
                    '<div class="video">\n' +
                    '    <div class="figure__image--wrapper" data-video-id="' +
                    cfOriginalVideoId +
                    '">\n' +
                    '        <img src="' +
                    cfPoster +
                    '" class="figure__image open-media-viewer"/>\n' +
                    '    </div>\n' +
                    '</div>';
                cfExtraLinks =
                    `
                        <div class="extra-links go-to-video">
                            <div class="video-meta">
                                <span>` +
                    cfName +
                    `</span>
                                <span>` +
                    cfText +
                    `</span>
                            </div>

                            <a href=#` +
                    cfOriginalVideoId +
                    `  title="Go to Video" class="back">
                                <i class=\"icon-subdirectory_arrow_left\"></i>
                            </a>
                        </div>
                    `;
                $('.pill-media__content').append(
                    '<li class="section__separator">' + cfMediaItem + cfExtraLinks + '</li>'
                );
            });
        }
        slide.mediaMoved = true;
    };

    slide.addTransition = function () {
        $('.w-slide, .w-slide__content').css('transition', 'all 250ms, width 1ms');
    };

    let setScrollVal = function (val, position) {
        $('html, body').animate({scrollTop: $(window).scrollTop() + val});

        if (position === 'top') {
            slide.$pillSection.animate({top: parseInt(slide.$pillSection.css('top')) - val});
        }
    };

    slide.on.additionalShow = function () {
        var $pill = $('.pill-list:visible'),
            windowOffsetBottom = $(window).scrollTop() + $(window).outerHeight(),
            pillOffsetBottom = $pill.outerHeight() + $pill.offset().top,
            pillToggle = slide.$toggle.data('slide-target'),
            stickyElements = 0,
            windowOffsetTopWithStickyElements,
            pillOffsetTop;

        if (slide.$toggle.is('[data-slide-target*="#pill"]')) {
            if (!slide.isMobile) {
                stickyElements = UX.scrollo.get.fixedpageElementsHeight();
                windowOffsetTopWithStickyElements = $(window).scrollTop() + stickyElements;
                pillOffsetTop = $pill.offset().top;

                if (windowOffsetBottom < pillOffsetBottom) {
                    setScrollVal(pillOffsetBottom - windowOffsetBottom, 'top');
                } else if (windowOffsetTopWithStickyElements > pillOffsetTop) {
                    setScrollVal(
                        -1 * (windowOffsetTopWithStickyElements - pillOffsetTop + UX.sticko.marginTopVal),
                        'down'
                    );
                }
            }
            $('.pill-list li').removeClass('pill-active');
            $('.pill-list li a').css('pointer-events', '');

            $('.pill-list:visible')
                .find('[data-slide-target=' + pillToggle + ']')
                .closest('li')
                .addClass('pill-active');
            $('.pill-list:visible')
                .find('[data-slide-target=' + pillToggle + ']')
                .closest('li a')
                .css('pointer-events', 'none');
        }
        if (slide.multi && !slide.$pillSection.hasClass('js--open')) {
            slide.setPillTop();
        }
    };

    let calcMarginTopValue = function (open) {
        var marginTopValue = 0;
        if (!slide.isMobile) {
            $('.fixed-element').each(function () {
                marginTopValue += $(this).outerHeight();
            });
        }

        if (open) {
            marginTopValue += $('.w-slide_head').outerHeight();
            if ($('.nested--1 .tab__nav').length) marginTopValue += $('.nested--1 .tab__nav').outerHeight();
        }
        return marginTopValue;
    };

    slide.setPillTop = function () {
        slide.$pillSection.addClass('js--open');
        slide.$elements.css('margin-top', calcMarginTopValue(false));
        slide.wait = true;
        slide.setTop();
    };

    slide.on.slidePill = function () {
        var $pillSection = slide.$pillSection,
            mainWidth = $('main').width(),
            leftPercentage = 0.28,
            leftValue;
        if (slide.multi && !slide.$pillSection.hasClass('.js--open')) {
            if ($('.w-slide__pill').outerWidth() <= 440) leftPercentage = $('.w-slide__pill').outerWidth() / mainWidth;
            leftValue = mainWidth - mainWidth * leftPercentage;
            $pillSection.delay(40).animate(
                {
                    left: leftValue - 70,
                },
                100
            );
        }
    };

    slide.on.additionalShowSlide = function () {
        if (typeof slide.pillInformationImages !== 'undefined') {
            slide.pillInformationImages?.update();
        }

        setTimeout(function () {
            $('[class*=pill][class*=__content]').css(
                'height',
                $(window).outerHeight() - calcMarginTopValue(true) /*eslint-disable-line*/
            );
            UX.genericTruncate.init();
        }, 500);
    };

    slide.on.additionalOff = function () {
        var $pillSection = slide.$pillSection;
        $('.pill-list li a').css('pointer-events', '');
        if (UX.pageBody.isIE) {
            $pillSection.removeClass('js--open');
        }

        $pillSection.one(
            'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend MSTransitionEnd',
            function () {
                if (!$('.w-slide__pill ').is('.js--open')) {
                    $pillSection.removeClass('js--open');
                }
            }
        );

        $pillSection.css({
            left: slide.pillIconsInitLeft,
        });
        slide.setTop();
        slide.on.beforeReturnSlideContent();
    };

    slide.on.beforeReturnSlideContent = function () {
        slide.pillInformationTruncateText = $('.w-slide__pill, .pill-content').find(
            '.truncate-text, .truncate-list, .truncate-with-shadow'
        );
        slide.pillInformationTruncateText.truncate('destroy');
    };

    slide.setBackLabel = function () {
        if (slide.multi) {
            slide.$back.html('<i class=" icon-cancel-bold" aria-hidden="true"></i>');
            slide.$back.closest('.w-slide__back').attr('title', 'Close');
        } else slide.$back.html('<i class=" icon-arrow_l" aria-hidden="true"></i>' + slide.backLabel);
    };

    slide.additionalInitialization = function () {
        var $lastPillSlideElement,
            $focusElements = 'button,  a:not(.disabled) , input, select, textarea , [tabindex]:not([tabindex="-1"])',
            $pillSlideElements = $('.w-slide__pill.js--open').find($focusElements).not(':hidden'),
            $activePill = $('.pill-active'),
            $backButton = $('.w-slide_head .w-slide__back'),
            tabKey = keyCodes.TAB;
        $(document).on('slide:opened', function () {
            //call textFill for the metric-value in pill-metric tab
            if ($('.pill-active .slide-active').data('slide-target') === '#pill-metric') {
                UX.textFill.specElement($('.metric-value'));
            }

            setTimeout(function () {
                slide.$elements.css('margin-top', calcMarginTopValue(false)); /*eslint-disable-line*/
            }, 350);

            $lastPillSlideElement = $pillSlideElements.eq($pillSlideElements.length - 1);

            // this for slide that doesn't contains  tabs inside it .
            if ($lastPillSlideElement.closest('.tab__pane.active').length === 0) {
                slide.blurWithoutTabs($lastPillSlideElement, $activePill, $backButton);
            }

            // this for slide that contains tabs inside it .
            if ($('.w-slide__pill .tab__pane.active').length > 0) {
                $lastPillSlideElement = $('.w-slide__pill .tab__pane.active').find($focusElements);
                $lastPillSlideElement = $lastPillSlideElement.eq($lastPillSlideElement.length - 1);
                slide.blurWithTabs($lastPillSlideElement, $activePill, $backButton);
            }

            $(document).on('keydown', '.w-slide__back', function (e) {
                if (e.shiftKey && e.keyCode === tabKey) {
                    e.preventDefault();
                    $lastPillSlideElement.focus();
                }
            });

            $(document).on('keydown', '.w-slide__content .tab__nav li:not(.active)', function (e) {
                if (!e.shiftKey && e.keyCode === tabKey) {
                    e.preventDefault();
                    if ($activePill.next().length > 0) {
                        $activePill.next().find('a').focus();
                    } else {
                        $backButton.focus();
                    }
                }
            });

            $(document).on('keydown', '.w-slide__btn:not(.pill-content)', function (e) {
                var $slide__btn = $(this).closest('li');
                if ($slide__btn.next('li').length === 0 || $slide__btn.prev('li').length === 0) {
                    slide.on.off(e);
                }
            });

            $(document).on('click', '.w-slide__pill a.back', function () {
                $('.w-slide__pill .w-slide__back').trigger('click');
            });
        });

        $(document).on('slide:closed', function () {
            if ($lastPillSlideElement) {
                $lastPillSlideElement.off('focusout');
                $(document).off('keydown', '.w-slide__content .tab__nav');
            }
        });
    };

    slide.blurWithoutTabs = function ($lastPillSlideElement, $activePill, $backButton) {
        $lastPillSlideElement.on('keydown', function (e) {
            if (!e.shiftKey && e.keyCode === keyCodes.TAB) {
                e.preventDefault();
                if ($activePill.next().length > 0) {
                    $activePill.next().find('a').focus();
                } else {
                    $backButton.focus();
                }
            }
        });
    };

    slide.blurWithTabs = function ($lastPillSlideElement, $activePill, $backButton) {
        $lastPillSlideElement.on('keydown', function (e) {
            var $this = $(this),
                $activeTab = $this.closest('.tab').find('.tab__nav li.active');
            if (!e.shiftKey && e.keyCode === keyCodes.TAB) {
                e.preventDefault();
                if ($activeTab.next('li:visible').length) {
                    $activeTab.next('li:visible').find('a').focus();
                } else if ($activePill.next().length > 0) $activePill.next().find('a').focus();
                else {
                    $backButton.focus();
                }
            }
        });
    };

    slide.setTop = function () {
        var fixedElementsHeight = UX.scrollo.get.fixedpageElementsHeight(),
            menuOffsetBottom = $('.sub-nav').offset().top + $('.sub-nav').outerHeight(),
            stickyOffsettop = slide.$articleMainContent.offset().top;
        slide.pillIconsInitLeft = slide.$articleMainContent.offset().left + slide.$articleMainContent.outerWidth();

        if (UX.pageBody.isIE) {
            $('.pill-section .pill-icons, .sidebar-section .article__sections').each(function () {
                var $this = $(this),
                    conditionTopVal = menuOffsetBottom + UX.sticko.marginTopVal - stickyOffsettop,
                    topVal =
                        conditionTopVal > 0
                            ? fixedElementsHeight + UX.sticko.marginTopVal
                            : stickyOffsettop - $(window).scrollTop(),
                    conditionBottomVal = stickyOffsettop + slide.$articleMainContent.outerHeight(),
                    stickyBounderOffsettop = $this.outerHeight() + UX.sticko.marginTopVal + fixedElementsHeight;

                if ($(window).scrollTop() - (conditionBottomVal - stickyBounderOffsettop) > 0) {
                    topVal = topVal - ($(window).scrollTop() - (conditionBottomVal - stickyBounderOffsettop));
                }

                if (!$('.sub-nav').length) {
                    topVal = 0;
                }

                $this.css({
                    top: topVal,
                    position: 'fixed',
                    width: $this.parent().width(),
                });
            });
        } else {
            $('.pill-section, .sidebar-section').each(function () {
                var $this = $(this),
                    conditionTopVal = menuOffsetBottom + UX.sticko.marginTopVal - stickyOffsettop,
                    topVal =
                        conditionTopVal > 0
                            ? fixedElementsHeight + UX.sticko.marginTopVal
                            : stickyOffsettop - $(window).scrollTop();

                if ($this.is('.pill-section')) {
                    if (conditionTopVal < 0) {
                        $this
                            .css({
                                top: topVal,
                                left: slide.pillIconsInitLeft,
                                transition: 'left .3s',
                            })
                            .addClass('js--fixed')
                            .removeClass('js--sticky');
                    } else {
                        $this
                            .css({
                                top: topVal,
                                left: slide.pillIconsInitLeft,
                                transition: 'all .3s',
                            })
                            .addClass('js--sticky')
                            .removeClass('js--fixed');
                    }
                }

                if ($this.is('.sidebar-section')) {
                    if (conditionTopVal < 0) {
                        $this.css({
                            top: UX.sticko.marginTopVal,
                            transition: 'none',
                        });
                    } else {
                        $this.css({
                            top: topVal,
                            transition: 'top .3s',
                        });
                    }
                }
            });
        }
    };

    slide.playVideoStream = function () {
        $('body').delegate('.pill-media__content .video .figure__image--wrapper', 'click', function () {
            var $streamPlayerID = $(this).attr('data-video-id');

            if (document.getElementById($streamPlayerID).requestFullscreen) {
                document.getElementById($streamPlayerID).requestFullscreen();
            } else if (document.getElementById($streamPlayerID).mozRequestFullScreen) {
                document.getElementById($streamPlayerID).mozRequestFullScreen();
            } else if (document.getElementById($streamPlayerID).webkitRequestFullscreen) {
                document.getElementById($streamPlayerID).webkitRequestFullscreen();
            } else if (document.getElementById($streamPlayerID).msRequestFullscreen) {
                document.getElementById($streamPlayerID).msRequestFullscreen();
            }
        });
    };
})(UX);
