(function ($) {
    var methods;

    function seeMoreLink() {
        var seeMore =
                ' <span class="ellipsis">' +
                this.settings.ellipsisChar +
                '</span> ' +
                this.settings.seeMoreText +
                ' <i class=" ' +
                this.settings.seeMoreIcon +
                ' "></i>',
            link;

        if (this.settings.seeMoreHtml) {
            seeMore = this.settings.seeMoreHtml;
        } else if (this.settings.isMobile) {
            seeMore =
                ' <span class="ellipsis">' +
                this.settings.ellipsisChar +
                '</span> ' +
                this.settings.seeMoreText +
                ' <i class=" ' +
                this.settings.seeMoreIconMobile +
                ' "></i>';
        }

        if (this.settings.isMobile && this.isSliding) {
            link =
                '<a href="javascript:void(0)" class="read-more w-slide__btn slide-active" data-slide-target="' +
                this.settings.mobileTarget +
                '" aria-expanded="false">' +
                seeMore +
                '</a>';
        } else {
            link = '<span class="read-more" aria-expanded="false" role="button" tabindex="0">' + seeMore + '</span>';
        }

        if (this.parentTagName === 'UL') {
            link = '<li>' + link + '</li>';
        }

        return $(link);
    }

    function ellipsis(isLink) {
        return $(
            '<' +
                (isLink ? 'a href="#"' : 'span') +
                ' class="ellipsis"> ' +
                this.settings.ellipsisChar +
                ' </' +
                (isLink ? 'a' : 'span') +
                '>'
        );
    }

    function removeElements(containerEl) {
        var that = this;
        var $this = $(this);
        var height = $this.height();
        var maxHeight = this.settings.lines * Math.ceil(parseFloat($this.css('line-height')));
        var $lastRemoved;
        var $parent;
        var $lastChild;
        var $seeMore;
        var textContent;
        var min;
        var max;
        var mid;

        if (this.settings.seeMoreLink) {
            $seeMore = seeMoreLink.call(this);
        } else {
            $seeMore = ellipsis.call(this);
        }

        if (containerEl.get(0).nodeType === Node.ELEMENT_NODE) {
            while (height > maxHeight && containerEl.contents().length) {
                $lastRemoved = containerEl.contents().last().remove();
                height = $this.height();
            }

            if (height <= maxHeight && $lastRemoved) {
                if ($.trim($lastRemoved.text()) !== '') {
                    containerEl.append($lastRemoved);
                    height = $this.height();
                    if (height > maxHeight) {
                        removeElements.call(this, $lastRemoved);
                    }
                } else {
                    $this.append($seeMore);
                    if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                        setTimeout(function () {
                            $this.addClass(that.settings.addClass);
                        }, 0);
                    }
                    $this.data('truncationDone', true);
                    that.settings.onComplete();
                }
            } else if ($.trim(containerEl.text()) === '') {
                $parent = containerEl.parent();
                containerEl.remove();
                height = $this.height();
                if ($.trim($parent.text()) === '') {
                    removeElements.call(this, $parent);
                } else if (height <= maxHeight) {
                    if ($this.children().length) {
                        $lastChild = $this.children().last();
                        if ($lastChild.is('br')) {
                            $lastChild.after($seeMore);
                        } else {
                            $lastChild.parent().append($seeMore);
                        }
                    } else {
                        $this.append($seeMore);
                    }
                    if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                        setTimeout(function () {
                            $this.addClass(that.settings.addClass);
                        }, 0);
                    }
                    $this.data('truncationDone', true);
                    that.settings.onComplete();
                }
            }
        } else {
            textContent = containerEl.text();
            min = 0;
            max = textContent.length - 1;
            mid = Math.floor((min + max) / 2);
            $parent = containerEl.parent();

            while (min < max) {
                if (height > maxHeight) {
                    max = mid + parseInt(((max - mid) * maxHeight) / height, 10);
                } else {
                    min = mid + 1;
                }
                $seeMore.remove();
                mid = Math.floor((min + max) / 2);
                containerEl.get(0).textContent = textContent.substr(
                    0,
                    this.settings.midWordEnabled ? mid : Math.min(mid, textContent.lastIndexOf(' ', mid))
                );
                if ($.trim($parent.text()) === '') {
                    removeElements.call(this, $parent);
                    return;
                }
                containerEl.parent().append($seeMore);
                height = $this.height();
            }

            while (height > maxHeight && mid > 0) {
                $seeMore.remove();
                mid = mid - 1;
                containerEl.get(0).textContent = textContent.substr(
                    0,
                    this.settings.midWordEnabled ? mid : Math.min(mid, textContent.lastIndexOf(' ', mid))
                );
                if ($.trim($parent.text()) === '') {
                    removeElements.call(this, $parent);
                    return;
                }
                containerEl.parent().append($seeMore);
                height = $this.height();
            }
            if (mid === 0) {
                $seeMore.remove();
                if ($.trim($parent.text()) === '') {
                    removeElements.call(this, $parent);
                }
            }

            if (height <= maxHeight) {
                if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                    setTimeout(function () {
                        $this.addClass(that.settings.addClass);
                    }, 0);
                }
                $this.data('truncationDone', true);
                that.settings.onComplete();
            }
        }
    }

    function truncateText() {
        var that = this;
        var $this = $(this);
        var height = $this.height();
        var maxHeight = this.settings.lines * parseFloat($this.css('line-height'));
        var visible = !$this.closest(':not(:visible)').length;

        if (visible && height === 0) {
            setTimeout(function () {
                truncateText.call(that);
            }, 30);
            return;
        }

        if (height > maxHeight) {
            removeElements.call(this, $this);
        } else {
            setTimeout(function () {
                $this.addClass(that.settings.addClass);
            }, 0);
            $this.data('truncationDone', true);
            that.settings.onComplete();
        }
    }

    function seeMoreMiddleLink() {
        var seeMore =
                ' <span class="ellipsis">' +
                this.settings.ellipsisChar +
                '</span> ' +
                this.settings.seeMoreText +
                ' <i class=" ' +
                this.settings.seeMoreIcon +
                ' "></i> <span class="ellipsis">' +
                this.settings.ellipsisChar +
                '</span> ',
            link;

        if (this.settings.seeMoreHtml) {
            seeMore = this.settings.seeMoreHtml;
        } else if (this.settings.isMobile) {
            seeMore =
                ' <span class="ellipsis">' +
                this.settings.ellipsisChar +
                '</span> ' +
                this.settings.seeMoreText +
                ' <i class="' +
                this.settings.seeMoreIconMobile +
                '"></i> <span class="ellipsis">' +
                this.settings.ellipsisChar +
                '</span> ';
        }

        if (this.settings.isMobile) {
            link =
                '<a href="javascript:void(0)" class="read-more w-slide__btn slide-active " data-slide-target="' +
                this.settings.mobileTarget +
                '" aria-expanded="false">' +
                seeMore +
                '</a>';
        } else {
            link = '<a href="javascript:void(0)" class="read-more" aria-expanded="false">' + seeMore + '</a>';
        }

        if (this.parentTagName === 'UL') {
            link = '<li>' + link + '</li>';
        }

        return $(link);
    }

    function removeListItemsMiddle() {
        var that = this;
        var $this = $(this);
        var height;
        var lineHeight = Math.ceil(Math.max($this.children().height(), parseFloat($this.css('line-height'))));
        var maxHeight = this.settings.lines * lineHeight;
        var $seeMore;
        var removedCount = 0;
        var $removedItems;
        var $lastRemoved;
        var visible = !$this.closest(':not(:visible)').length;
        var removeItems;
        var indexToRemove;

        if (this.settings.seeMoreLink) {
            $seeMore = seeMoreMiddleLink.call(this);
        } else {
            $seeMore = ellipsis.call(this, true);
        }

        removeItems = function () {
            height = $this.height();
            if (!visible) return;
            if (height > maxHeight) {
                while (height > maxHeight && $this.contents().length > 2) {
                    $seeMore.remove();
                    if ($removedItems && $this.find($removedItems).length) {
                        $removedItems.remove();
                    }
                    indexToRemove = Math.floor($this.contents().length / 2);

                    $lastRemoved = $($this.contents().get(indexToRemove)).remove();
                    if ($lastRemoved && $lastRemoved.get(0).nodeType === Node.ELEMENT_NODE) {
                        if (that.settings.ignoreCountList !== null) {
                            if (!$lastRemoved.is(that.settings.ignoreCountList)) {
                                removedCount += 1;
                            }
                        } else {
                            removedCount += 1;
                        }
                    }
                    indexToRemove -= 1;
                    height = $this.height();

                    if (height > maxHeight && $this.contents().length > 2) {
                        $lastRemoved = $($this.contents().get(indexToRemove)).remove();
                        if ($lastRemoved && $lastRemoved.get(0).nodeType === Node.ELEMENT_NODE) {
                            if (that.settings.ignoreCountList !== null) {
                                if (!$lastRemoved.is(that.settings.ignoreCountList)) {
                                    removedCount += 1;
                                }
                            } else {
                                removedCount += 1;
                            }
                        }
                        indexToRemove -= 1;
                    }
                    if (that.settings.showRemovedCount && removedCount > 0) {
                        $removedItems = $.fn.truncate.removedCountMarkup(removedCount);
                        $removedItems.insertAfter($($this.contents().get(indexToRemove)));
                    } else {
                        $seeMore.insertAfter($($this.contents().get(indexToRemove)));
                    }

                    height = $this.height();
                }
                setTimeout(removeItems, 500);
            } else {
                if (that.settings.addClass && !$this.hasClass(that.settings.addClass)) {
                    setTimeout(function () {
                        $this.addClass(that.settings.addClass);
                    }, 0);
                }
                $this.data('truncationDone', true);
                that.settings.onComplete();
            }
        };

        removeItems();
    }

    function removeListItemsEnd() {
        var that = this;
        var $this = $(this);
        var height;
        var lineHeight = Math.ceil(Math.max($this.children().height(), parseFloat($this.css('line-height'))));
        var maxHeight = this.settings.lines * lineHeight;
        var $seeMore;
        var removedCount = 0;
        var $removedItems;
        var $lastRemoved;
        var visible = !$this.closest(':not(:visible)').length;
        var removeItems;

        if (this.settings.seeMoreLink) {
            $seeMore = seeMoreLink.call(this);
        } else {
            $seeMore = ellipsis.call(this);
        }

        removeItems = function () {
            height = $this.height();
            if (!visible) return;
            if (height > maxHeight) {
                while (height > maxHeight && $this.contents().length > 1) {
                    if ($removedItems && $this.find($removedItems).length) {
                        $removedItems.remove();
                    }
                    if ($this.find($seeMore).length) {
                        $lastRemoved = $($this.contents().get($this.contents().length - 2)).remove();
                    } else {
                        $lastRemoved = $this.contents().last().remove();

                        if (!that.settings.showRemovedCount) {
                            $this.append($seeMore);
                        }
                    }
                    if ($lastRemoved && $lastRemoved.get(0).nodeType === Node.ELEMENT_NODE) {
                        if (that.settings.ignoreCountList !== null) {
                            if (!$lastRemoved.is(that.settings.ignoreCountList)) {
                                removedCount += 1;
                            }
                        } else {
                            removedCount += 1;
                        }
                    }

                    const randomId = [...Array(4)].map(() => Math.random().toString(36)[2]).join('');
                    const randomLabeledBy = [...Array(4)].map(() => Math.random().toString(36)[2]).join('');
                    $this.attr('id', 'authorsListId-' + randomId);
                    $this.attr('aria-labelledby', 'authorList-' + randomLabeledBy);

                    if (that.settings.showRemovedCount && removedCount > 0) {
                        $removedItems = $.fn.truncate.removedCountMarkup(
                            removedCount,
                            $this.attr('id'),
                            $this.attr('aria-labelledby')
                        );
                        $this.append($removedItems);
                    }

                    height = $this.height();
                }
                setTimeout(removeItems, 500);
            } else {
                if (that.settings.addClass && !$this.hasClass(that.settings.addClass)) {
                    setTimeout(function () {
                        $this.addClass(that.settings.addClass);
                    }, 0);
                }
                $this.data('truncationDone', true);
                that.settings.onComplete();
            }
        };

        removeItems();
    }

    function removeListItemsBetweenFirstAndLast() {
        const that = this,
            $this = $(this),
            $items = $this.children();

        let $seeMore,
            removedCount = $items.length - 2,
            $removedItems,
            firstItem,
            latsItem;

        if ($items.length > 2) {
            firstItem = $items.eq(0).clone();
            latsItem = $items.eq($items.length - 1).clone();
            if (this.settings.seeMoreLink) {
                $seeMore = seeMoreMiddleLink.call(this);
            } else {
                $seeMore = ellipsis.call(this, true);
            }

            if (that.settings.showRemovedCount) {
                $removedItems = $.fn.truncate.removedCountMarkup(removedCount);
            }

            $this.html('');
            $this.append(firstItem);
            if (that.settings.showRemovedCount && removedCount > 0) {
                $this.append($removedItems);
            } else {
                $this.append($seeMore);
            }
            $this.append(latsItem);
        }
        if (that.settings.addClass && !$this.hasClass(that.settings.addClass)) {
            setTimeout(function () {
                $this.addClass(that.settings.addClass);
            }, 0);
        }
        $this.data('truncationDone', true);
        that.settings.onComplete();
    }

    function removeListItemsBeforeLast() {
        var that = this;
        var $this = $(this);
        var height = $this.height();
        var lineHeight = Math.ceil(Math.max($this.children().height(), parseFloat($this.css('line-height'))));
        var maxHeight = this.settings.lines * lineHeight;
        var $seeMoreLink = seeMoreMiddleLink.call(this);
        var removedCount = 0;
        var $removedItems;
        var $lastRemoved;
        var visible = !$this.closest(':not(:visible)').length;
        var $lastXItems;
        var $lastXItemsContent;

        var removeItems = function () {
            height = $this.height();
            if (!visible) return;
            if (height > maxHeight) {
                $lastXItems = $('<div></div>');
                while (
                    ($this.children().length > 1 && $lastXItems.children().length < that.settings.lastItemsCount) ||
                    $this.contents().last().get(0).nodeType === Node.TEXT_NODE
                ) {
                    $lastXItems.prepend($this.contents().last().remove());
                }
                $lastXItemsContent = $lastXItems.contents();
                while (height > maxHeight && $this.children().length > 2) {
                    if ($removedItems && $this.find($removedItems).length) {
                        $removedItems.remove();
                    }
                    $seeMoreLink.remove();
                    $lastXItemsContent.remove();
                    $lastRemoved = $this.contents().last().remove();
                    if ($lastRemoved && $lastRemoved.get(0).nodeType === Node.ELEMENT_NODE) {
                        if (that.settings.ignoreCountList !== null) {
                            if (!$lastRemoved.is(that.settings.ignoreCountList)) {
                                removedCount += 1;
                            }
                        } else {
                            removedCount += 1;
                        }
                    }
                    if (that.settings.showRemovedCount && removedCount > 0) {
                        $removedItems = $.fn.truncate.removedCountMarkup(removedCount);
                        $this.append($removedItems);
                    } else {
                        $this.append($seeMoreLink);
                    }
                    $this.append($lastXItemsContent);
                    height = $this.height();
                }
                setTimeout(removeItems, 500);
            } else {
                if (that.settings.addClass && !$this.hasClass(that.settings.addClass)) {
                    setTimeout(function () {
                        $this.addClass(that.settings.addClass);
                    }, 0);
                }
                $this.data('truncationDone', true);
                that.settings.onComplete();
            }
        };

        removeItems();
    }

    function truncateList() {
        var that = this;
        var $this = $(this);
        var height = $this.height();
        var lineHeight = Math.ceil(Math.max($this.children().height(), parseFloat($this.css('line-height'))));
        var maxHeight = this.settings.lines * lineHeight;
        var visible = !$this.closest(':not(:visible)').length;

        if ($this.children().length === 0) {
            $this.addClass(that.settings.addClass);
            $this.data('truncationDone', true);
            that.settings.onComplete();
            return;
        }

        if (visible && (height === 0 || maxHeight === 0)) {
            setTimeout(function () {
                truncateList.call(that);
            }, 30);
            return;
        }

        switch (this.settings.position) {
            case 'middle':
                if (height > maxHeight) {
                    removeListItemsMiddle.call(this, $this);
                } else {
                    if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                        setTimeout(function () {
                            $this.addClass(that.settings.addClass);
                        }, 0);
                    }
                    $this.data('truncationDone', true);
                    that.settings.onComplete();
                }
                break;
            case 'end':
                if ($(this).closest('.hidden-xs.hidden-sm').length && $(this).height() === 0) {
                    let hiddenParent = $(this).closest('.hidden-xs.hidden-sm');
                    hiddenParent.addClass('hidden-removed');
                    hiddenParent.removeClass('hidden-xs hidden-sm');
                    height = $(this).height();
                }

                if (height > maxHeight) {
                    removeListItemsEnd.call(this, $this);
                    $('.hidden-removed').addClass('hidden-xs hidden-sm');
                } else {
                    if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                        setTimeout(function () {
                            $this.addClass(that.settings.addClass);
                        }, 0);
                    }
                    $this.data('truncationDone', true);
                    that.settings.onComplete();
                }
                break;
            case 'betweenFirstAndLast':
                removeListItemsBetweenFirstAndLast.call(this, $this);
                break;
            case 'beforeLast':
                if (height > maxHeight) {
                    removeListItemsBeforeLast.call(this, $this);
                } else {
                    if (this.settings.addClass && !$this.hasClass(this.settings.addClass)) {
                        setTimeout(function () {
                            $this.addClass(that.settings.addClass);
                        }, 0);
                    }
                    $this.data('truncationDone', true);
                    that.settings.onComplete();
                }
                break;
        }
    }

    function seeLessLink(id, ariaLabelledby) {
        var seeLess =
                (this.settings.lessLinkEllipsis
                    ? ' <span class="ellipsis">' + this.settings.ellipsisChar + '</span> '
                    : ' ') +
                this.settings.seeLessText +
                ' <i class="' +
                this.settings.seeLessIcon +
                '"></i>',
            link;

        if (this.settings.seeLessHtml) {
            seeLess = this.settings.seeLessHtml;
        }

        // If the content list has id, this will add aria-controls to the show less button
        const ariaControlsID = id ? `aria-controls=${id}` : '';
        // If the content list has aria-labelledby, this will add id to the show less button
        const ariaLabelledbyID = ariaLabelledby ? `id=${ariaLabelledby}` : '';

        link = `<button class="read-less" ${ariaLabelledbyID} ${ariaControlsID} aria-expanded="true">${seeLess}</button>`;

        if (this.parentTagName === 'UL') {
            link = '<li>' + link + '</li>';
        }

        return $(link);
    }

    function addEventHandlers() {
        var that = this;
        var $this = $(this);
        var allowedKeys = [9, 13]; // tabs and enter (return)
        var allowTabToReadMore = true;
        var checkHeight;
        var originalHeight;
        var $evtTarget;

        if (that.settings.readMoreWithSpacebar) {
            allowedKeys = [9, 13, 32]; // tabs and enter (return) and spacebar
        }

        if (that.settings.animation) {
            checkHeight = true;
        }

        $this.on('touchend mousedown keydown', function (evt) {
            if (evt.type === 'keydown' && allowedKeys.indexOf(evt.keyCode) < 0) {
                // preventing random keys from triggering action
                return;
            }

            if (that.settings.readMoreWithSpacebar) {
                allowTabToReadMore = evt.keyCode !== 9;
            }

            if (that.settings.animation) {
                if (checkHeight) {
                    originalHeight = $this.outerHeight(true);
                    checkHeight = false;
                }
            }

            function readMore() {
                var truncatedLength = $this.children().length,
                    lessLink = seeLessLink.call(that, $this.attr('id'), $this.attr('aria-labelledby')),
                    autoHeight;
                evt.preventDefault();
                $this.data('truncatedContent', $this.html());
                $this.html($this.data('originalContent'));
                $this.append(lessLink);
                $this.attr('aria-expanded', true);

                if (that.settings.animation) {
                    $this.css('height', 'auto');
                    $('.citation .loa').css('display', 'inline-block');
                    autoHeight = $this.outerHeight(true);
                    $this.height(originalHeight).animate(
                        {
                            height: autoHeight,
                        },
                        300
                    );
                    setTimeout(function () {
                        $this.css('height', 'auto');
                        $('.citation .loa').css('display', 'inline');
                    }, 350);
                }

                if (evt.type === 'keydown') {
                    if ($this[0].parentTagName === 'UL') {
                        $this
                            .children()
                            .eq(truncatedLength - 1)
                            .find('a')
                            .trigger('focus');
                    } else {
                        lessLink.focus();
                    }
                }
            }

            function readLess() {
                $this.attr('aria-expanded', false);
                if (evt.keyCode === 9) {
                    // preventing tabulation limbo
                    return;
                } else {
                    evt.preventDefault();
                }

                if (that.settings.animation) {
                    $this.animate({height: originalHeight}, 300);
                    setTimeout(function () {
                        $this.html($this.data('truncatedContent'));
                    }, 250);
                } else {
                    $this.html($this.data('truncatedContent'));
                }

                $this.find('.read-more').focus();
            }

            if ($this.data('truncationDone') && allowTabToReadMore && !$(this).hasClass('custom-truncation')) {
                $evtTarget = $(evt.target);
                if ($evtTarget.hasClass('ellipsis') && $evtTarget.is('a')) {
                    // ellipsis clicked
                    readMore();
                }

                if (
                    ($evtTarget.hasClass('read-more') && !$evtTarget.hasClass('w-slide__btn')) ||
                    ($evtTarget.parent().hasClass('read-more') && !$evtTarget.parent().hasClass('w-slide__btn')) ||
                    $evtTarget.hasClass('removed-items-count')
                ) {
                    // "Read more" link clicked
                    readMore();
                }

                if ($evtTarget.hasClass('read-less') || $evtTarget.parent().hasClass('read-less')) {
                    // "Read less" link clicked
                    readLess();
                }
            }
        });

        if (!$(document).data('activeTabHandlerAdded')) {
            $(document).data('activeTabHandlerAdded', 'yes');
            $(document).on('tabs:changeActiveTab', function (evt, data) {
                setTimeout(function () {
                    $('.truncate-delayed', data.tabId).truncate();
                }, 10);
            });
        }
    }

    function restoreContent() {
        var $this = $(this);
        if ($this.data('originalContent')) {
            $this.html($this.data('originalContent'));
            if (this.settings && this.settings.addClass) {
                $this.removeClass(this.settings.addClass);
            }
        }
    }

    function truncateStart() {
        var $this = $(this);
        if (this.settings.showRemovedCount) {
            $this.truncate('destroy'); // destroy in case showRemovedCount to get a correct number
        }

        if (
            (this.settings.isMobile && this.settings.targetViewport === 'desktop') ||
            (!this.settings.isMobile && this.settings.targetViewport === 'mobile')
        ) {
            restoreContent.call(this);
            return;
        }

        if ($this.closest('.tab__pane').length && !$this.closest('.tab__pane').hasClass('active')) {
            $this.addClass('truncate-delayed');
            return;
        } else {
            $this.removeClass('truncate-delayed');
        }

        if (this.settings && this.settings.type === 'text') {
            truncateText.call(this);
        } else if (this.settings && this.settings.type === 'list') {
            this.parentTagName = $this.get(0).tagName;
            truncateList.call(this);
        }
    }

    function addResponsiveHandlers() {
        var that = this;

        function targetViewportOn() {
            that.settings.isMobile = true;
            truncateStart.call(that);
        }

        function targetViewportOff() {
            that.settings.isMobile = false;
            truncateStart.call(that);
        }

        if (this.prevVPort) {
            // remove previous responsive handlers
            $(document).off(this.prevVPort + '-on', targetViewportOn);
            $(document).off(this.prevVPort + '-off', targetViewportOff);
        }

        $(document).on(this.settings.vPort + '-on', targetViewportOn); // Waiting for custom event that will be triggered by controller.js to activate responsive effects
        $(document).on(this.settings.vPort + '-off', targetViewportOff); // Waiting for custom event that will be triggered by controller.js to deactivate responsive effects

        this.prevVPort = this.settings.vPort;
    }

    methods = {
        init: function (options) {
            return this.each(function () {
                var $this = $(this);
                var firstInit = true;
                var dataAttrOptions = {};
                if ($this.data('originalContent')) {
                    if ($this.data('settings')) {
                        this.settings = $this.data('settings');
                    }
                    restoreContent.call(this);
                    firstInit = false;
                } else {
                    $this.data('originalContent', $this.html());
                }

                // read configuration from data attributes
                if ($this.data('truncate-lines')) {
                    dataAttrOptions.lines = parseInt($this.data('truncate-lines'), 10);
                }
                if ($this.data('truncate-type')) {
                    dataAttrOptions.type = $this.data('truncate-type');
                }
                if ($this.data('truncate-position')) {
                    dataAttrOptions.position = $this.data('truncate-position');
                }
                if (typeof $this.data('truncate-show-removed-count') !== 'undefined') {
                    dataAttrOptions.showRemovedCount = $this.data('truncate-show-removed-count');
                }
                if (typeof $this.data('truncate-see-more-link') !== 'undefined') {
                    dataAttrOptions.seeMoreLink = $this.data('truncate-see-more-link');
                }
                if ($this.data('truncate-see-more-text')) {
                    dataAttrOptions.seeMoreText = $this.data('truncate-see-more-text');
                }
                if ($this.data('truncate-see-less-text')) {
                    dataAttrOptions.seeLessText = $this.data('truncate-see-less-text');
                }
                if ($this.data('truncate-see-more-html')) {
                    dataAttrOptions.seeMoreHtml = $this.data('truncate-see-more-html');
                }
                if ($this.data('truncate-see-less-html')) {
                    dataAttrOptions.seeLessHtml = $this.data('truncate-see-less-html');
                }
                if ($this.data('truncate-ellipsis-char')) {
                    dataAttrOptions.ellipsisChar = $this.data('truncate-ellipsis-char');
                }
                if ($this.data('truncate-last-items-count')) {
                    dataAttrOptions.lastItemsCount = parseInt($this.data('truncate-last-items-count'), 10);
                }
                if ($this.data('truncate-mobile-target')) {
                    dataAttrOptions.mobileTarget = $this.data('truncate-mobile-target');
                }
                if ($this.data('truncate-add-class')) {
                    dataAttrOptions.addClass = $this.data('truncate-add-class');
                }
                if ($this.data('truncate-vport')) {
                    dataAttrOptions.vPort = $this.data('truncate-vport');
                }
                if ($this.data('truncate-target-viewport')) {
                    dataAttrOptions.targetViewport = $this.data('truncate-target-viewport');
                }
                if (typeof $this.data('truncate-mid-word-enabled') !== 'undefined') {
                    dataAttrOptions.midWordEnabled = $this.data('truncate-mid-word-enabled');
                }
                if (typeof $this.data('truncate-less-link-ellipsis') !== 'undefined') {
                    dataAttrOptions.lessLinkEllipsis = $this.data('truncate-less-link-ellipsis');
                }

                this.settings = $.extend({}, this.settings || $.fn.truncate.defaults, dataAttrOptions, options || {});
                $this.data('settings', this.settings);
                $this.data('truncationDone', false);
                truncateStart.call(this);

                if (firstInit) {
                    addEventHandlers.call(this);
                }

                addResponsiveHandlers.call(this);
            });
        },
        destroy: function () {
            return this.each(function () {
                restoreContent.call(this);
            });
        },
    };

    $.fn.truncate = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + method + ' does not exist on jQuery.truncate');
        }
    };

    $.fn.truncate.removedCountMarkup = function (count, id, ariaLabelledby) {
        // If the content list has id, this will add aria-controls to the expand button
        const ariaControlsID = id ? `aria-controls=${id}` : '';
        // If the content list has aria-labelledby, this will add id to the expand button
        const ariaLabelledbyID = ariaLabelledby ? `id=${ariaLabelledby}` : '';

        return $(
            `<li class="count-list"><button  class="removed-items-count" ${ariaLabelledbyID} ${ariaControlsID} aria-expanded="false" aria-label="View other ${count} authors"> + ${count} </button></li>`
        );
    };

    /* Default truncate settings. */
    $.fn.truncate.defaults = {
        type: 'text', // text, list
        position: 'end', // end, middle, beforeLast, betweenFirstAndLast
        showRemovedCount: false,
        lines: 1,
        seeMoreLink: false,
        seeMoreText: 'See More',
        seeLessText: 'See Less',
        seeMoreIcon: 'icon-section_arrow_d',
        seeLessIcon: 'icon-section_arrow_u',
        seeMoreIconMobile: 'icon-arrow_r',
        seeMoreHtml: '',
        seeLessHtml: '',
        ellipsisChar: '&hellip;',
        lessLinkEllipsis: false,
        lastItemsCount: 1,
        isMobile: false,
        isSliding: true,
        mobileTarget: '',
        targetViewport: 'all', // all, desktop, mobile
        addClass: '',
        vPort: 'screen-sm',
        midWordEnabled: false,
        animation: false,
        ignoreCountList: null,
        readMoreWithSpacebar: false,
        onComplete: function () {},
    };

    function hideTruncatedFocusableElements() {
        const cssTruncatedElements = $('.truncate-text-css');
        cssTruncatedElements?.each(function () {
            const container = $(this);
            const containerRect = this.getBoundingClientRect();
            const truncatedElementsElements = container.find('a, button, input, textarea, select');
            if (truncatedElementsElements.length > 1) {
                truncatedElementsElements.each(function () {
                    const element = $(this);
                    const elementRect = this.getBoundingClientRect();
                    if (elementRect.bottom > containerRect.bottom) {
                        if (element.closest('.slide-item').length) {
                            element.css('display', 'none');
                        } else {
                            element.attr('tabindex', '-1');
                        }
                    }
                });
            }
        });
    }
    window.addEventListener('load', hideTruncatedFocusableElements);
})(jQuery);
