import Stickyfill from 'stickyfilljs';
window.Stickyfill = Stickyfill;

(function () {
    var proceedings = {
        lettersList: '',
        proceedingsWrapper: $('.accordion--conferences'),
        proceedingsItems: 0,

        init: function () {
            if (UX.pageBody.isIE) {
                proceedings.setTop($('.proceedings-browse__control'));

                $(document).on('screen-sm-on', function () {
                    proceedings.setTop($('.proceedings-browse__control'));
                });

                $(document).on('screen-sm-off', function () {
                    proceedings.setTop($('.proceedings-browse__control'));
                });
            }

            UX.accordion.on.expanded = function ($this) {
                if ($this.hasClass('proceedings-browse__control')) {
                    proceedings.setTop($this);
                }
            };
            $(document).ready(function () {
                $('.accordion-tabbed__control').removeClass('disabledLink');
            });
            if ($('.load-gradually').length) {
                UX.proceedings.initLoad();
            }
            UX.proceedings.highlightProceeding();
        },
        initLoad: function () {
            proceedings.makeSureToLoad();
            proceedings.isLoaded();
        },
        highlightProceeding: function () {
            var $proceedingSearch = $('.proceeding-search-container'),
                $inputVal,
                $inputValWords;
            if ($proceedingSearch.length) {
                $inputVal = $proceedingSearch.find('.quick-search__input').val();
                if ($inputVal.length && $('.proceedings-browse--alphabetical').length) {
                    $inputValWords = $inputVal.replace(/\"/gi, '').split(' ');
                    $inputValWords.unshift($inputVal);

                    $('.proceedings-browse--alphabetical').highlight($inputValWords);
                    setTimeout(function () {
                        $('.proceedings-browse--alphabetical').highlight($inputValWords);
                    }, 500);
                }
            }
        },
        gradualyLoad: function () {
            window.dataProceedingsJson.forEach(function (items, topIndex) {
                Object.keys(items).forEach(function (item) {
                    $('.accordion--conferences > ul')
                        .delay(50)
                        .queue(function (next) {
                            proceedings.loadItem(items, item, topIndex + 1);
                            $(this).append(proceedings.lettersList);

                            $('.accordion--conferences > ul > .accordion-tabbed__tab:not(:first-child)').addClass(
                                'fadeInUp'
                            );

                            next();
                        });
                });
            });
        },
        loadItem: function (items, item) {
            let confText;
            if ($('.light__proceedings').length) {
                confText = 'Workshops';
            } else {
                confText = 'Conferences';
            }

            proceedings.lettersList =
                '<li class="accordion-tabbed__tab" style="display: none">' +
                '<a href="#" title=' +
                item +
                ' aria-expanded="false" aria-controls="proceeding-' +
                item +
                '" ' +
                'aria-label="alphanumeric title ' +
                item +
                ', ' +
                items[item].length +
                `${items[item].length > 1 ? ' conferences' : ' conference'}` +
                ' available"' +
                'class="accordion-tabbed__control proceedings-browse__control">' +
                '<span class="accordion-tabbed__control-title">' +
                '<span class="title">' +
                item +
                '</span>' +
                '<span class="count">' +
                items[item].length +
                ' ' +
                confText +
                '</span>' +
                '</span>' +
                '</a>' +
                '<div id="proceeding-' +
                item +
                '" style="display: none" class="accordion-tabbed__content proceedings-browse__content">' +
                '<div class="accordion-with-arrow">' +
                '<div class="accordion accordion--proceedings"><ul class="accordion-tabbed rlist">';

            items[item].forEach(function (lettreItems, index) {
                proceedings.lettersList += '<li class="accordion-tabbed__tab">';

                if (lettreItems.ajaxLink) {
                    proceedings.lettersList +=
                        '<a class="accordion-tabbed__control loadAjax" href="' +
                        lettreItems.botLink +
                        '" title="' +
                        lettreItems.label +
                        '" aria-expanded="false" aria-controls="conference-' +
                        item +
                        '-' +
                        index +
                        '" data-component="proceedings" data-ajaxurl="' +
                        lettreItems.ajaxLink +
                        '" data-ajaxtarget="#conference' +
                        item +
                        '-' +
                        index +
                        ' ul">';
                } else {
                    proceedings.lettersList +=
                        '<a class="accordion-tabbed__control" href="javascript:void(0)" title="' +
                        lettreItems.label +
                        '" aria-expanded="false" aria-controls="conference-' +
                        item +
                        '-' +
                        index +
                        '" >';
                }
                proceedings.lettersList +=
                    '<span class="accordion-tabbed__control-title"><span class="title">' +
                    lettreItems.label +
                    '</span><span class="count">';
                if (!lettreItems.ajaxLink) {
                    proceedings.lettersList += lettreItems.count + ' Proceedings';
                }
                proceedings.lettersList +=
                    '</span></span></a>' +
                    '<div id="conference' +
                    item +
                    '-' +
                    index +
                    '" style="display: none;" class="accordion-tabbed__content clearfix">' +
                    '<div data-numberoflitoshow="5" data-useanimation="false" data-showmorestr="<span class=\'show-more-items__btn-holder link\'><span class=\'btn\'>View All Proceedings</span></span>" data-showmorelabel="View All Proceedings" data-showlesslabel="View less Proceedings" class="show-more-items">' +
                    '<ul class="dotted-list" >';

                if (!lettreItems.ajaxLink) {
                    proceedings.lettersList += proceedings.loadProceedings(lettreItems.items);
                }
                proceedings.lettersList +=
                    '</ul></div><a class="pull-right link" href=" ' +
                    lettreItems.conferenceUrl +
                    ' ">View Conference<i aria-hidden="true" class="icon-Arrow-Stroke"></i></a></div></li>';
            });

            proceedings.lettersList += '</ul></div></div></div></li>';
        },

        loadProceedings: function (items) {
            var proceedingItems = '';
            items.forEach(function (lettreItem) {
                var sponsoredMark = '';
                if (!lettreItem.sponsored && typeof lettreItem.sponsored !== 'undefined') {
                    sponsoredMark = '<span class="sponsored">‡</span>';
                }
                proceedingItems +=
                    '<li><a href="' + lettreItem.link + '"><h4>' + lettreItem.title + sponsoredMark + '</h4></a></li>';
            });

            return proceedingItems;
        },
        makeSureToLoad: function () {
            if (window.shouldHaveData && window.dataProceedingsJson !== '') {
                proceedings.proceedingsItems = window.dataProceedingsJson.length + 1;
                proceedings.gradualyLoad();
            } else {
                setTimeout(proceedings.makeSureToLoad, 250);
            }
        },

        isLoaded: function () {
            if (
                $(
                    '.accordion--conferences > ul > .accordion-tabbed__tab:nth-child(' +
                        proceedings.proceedingsItems +
                        ')'
                ).length
            ) {
                UX.showMore.init();
            } else {
                setTimeout(proceedings.isLoaded, 500);
            }
        },

        setTop: function ($el) {
            $el.each(function () {
                var $this = $(this);
                window.Stickyfill.remove($this);
                $this.css('top', UX.pageBody.fixedHeight);
                window.Stickyfill.addOne($this);
            });
        },

        getAjaxContent: function ($toggle, $target, ajaxURl) {
            $.ajax({
                url: ajaxURl,
                type: 'GET',
                success: function (data) {
                    var resultData = data;
                    if (UX.ajaxControl.mustParseJson(resultData)) {
                        resultData = JSON.parse(resultData);
                    }
                    $target.append(proceedings.loadProceedings(resultData.data.proceedings)).find('.loader').remove();
                    $toggle
                        .addClass('ajax-done')
                        .find('.count')
                        .html(resultData.data.count + ' Proceedings');
                    UX.proceedings.highlightProceeding();
                    UX.showMore.init();
                },
            });
        },
    };

    UX.proceedings = proceedings; // add to global namespace
})();
